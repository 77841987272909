import colors from './colors';
import components from './components';
import fonts from './fonts';
import fontSizes from './fontsizes';
import styles from './styles';

const theme = {
  colors,
  components,
  fonts,
  fontSizes,
  styles,
};

export default theme;
