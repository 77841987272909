import { theme as chakraTheme } from '@chakra-ui/react';

const { Button, Container, Divider, Heading, Input, Modal } = chakraTheme.components;

const components = {
  Button,
  Container,
  Divider,
  Heading,
  Input,
  Modal,
};

export default components;
