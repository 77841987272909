const fontSizes = {
  9: '0.5625rem',
  10: '0.625rem',
  11: '0.6875rem',
  12: '0.75rem',
  13: '0.8125rem',
  14: '0.875rem',
  15: '0.9375rem',
  16: '1rem',
  17: '1.0625rem',
  18: '1.125rem',
  19: '1.125rem',
  20: '1.25rem',
  21: '1.3125rem',
  22: '1.375rem',
  23: '1.4375rem',
  24: '1.5rem',
  25: '1.5625rem',
  26: '1.625rem',
  27: '1.6875rem',
  28: '1.75rem',
  29: '1.8125rem',
  30: '1.875rem',
  32: '2rem',
  34: '2.25em',
  35: '2.1875rem',
  40: '2.5rem',
  42: '2.625rem',
  48: '3rem',
  50: '3.125rem',
  60: '3.75rem',
};

export default fontSizes;
