const styles = {
  global: {
    body: {
      bg: 'whiteSmoke.100',
      color: 'black.100',

      '&.gradient': {
        bgGradient: 'linear(white.100 0%, ghostWhite.100 10%)',
        minH: '100dvh',
      },
    },
  },
};

export default styles;
