const colors = {
  black: {
    100: '#000',
  },
  blackBean: {
    100: '#0f1710',
  },
  ghostWhite: {
    100: '#edf0fe',
  },
  grey: {
    100: '#777',
  },
  mediumPurple: {
    100: '#7163e1',
  },
  white: {
    100: '#fff',
  },
  whiteSmoke: {
    100: '#f6f6f6',
  },
  zumthor: {
    100: '#d4dadc',
  },
};

export default colors;
